<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-location-sub pb-2" content-class="gst-slice-location-sub__content">
        <PrismicRichText :field="slice.primary.title" class="gst-slice__title gst-slice-location-sub__title" />
        <div class="gst-slice-location-sub__items-wrapper">
            <DropdownList :items="slice.items" />
        </div>
    </BaseSlice>
</template>

<script>
    import DropdownList from './components/DropdownList.vue';
    import NavMixin from './mixins/NavMixin.js';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'LocationSub',
        components: {
            DropdownList,
            BaseSlice
        },
        mixins: [
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        }
    };
</script>