<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-cta-simple-content">
        <div
            class="d-flex flex-md-row flex-column align-md-center pa-8"
            :style="setSectionBackgroundColor">
            <div v-if="showImage" class="mb-4 mr-md-6">
                <PrismicImage :field="slice.primary.icon_image" />
            </div>
            <div class="text-content d-flex flex-column justify-center mb-4 mr-md-4">
                <PrismicRichText :field="slice.primary.content_title" class="mb-2" />
                <PrismicRichText :field="slice.primary.description" />
            </div>
            <div v-if="showCtaButton" class="d-flex align-content-start align-content-md-end align-center ml-md-auto">
                <BaseButton
                    :disabled="isDisabled"
                    :placeholder="slice.primary.cta_button[0].text"
                    class="gst-slice-primary-button"
                    :href="href"
                    :target="target" />
            </div>
        </div>
    </BaseSlice>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import BaseSlice from './components/BaseSlice.vue';
    import NavMixin from './mixins/NavMixin.js';

    export default {
        name: 'SimpleContentWithCtaButton',
        components: { BaseSlice, BaseButton },
        mixins: [
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: undefined
            }
        },
        computed: {
            setSectionBackgroundColor() {
                return `background-color: ${this.slice.primary.background_color}`;
            },
            showImage() {
                return Object.keys( this.slice.primary.icon_image ).length > 0;
            },
            showCtaButton() {
                return this.slice.primary.cta_button.length > 0;
            },
            isDisabled() {
                return this.slice.primary.cta_is_disabled || this.slice.primary.cta_button[0].spans[0] === undefined;
            },
            href() {
                return  this.slice.primary.cta_button[0].spans.length > 0 ? this.slice.primary.cta_button[0].spans[0].data.url : '';
            },
            target() {
                return this.slice.primary.cta_button[0].spans.length > 0 ? this.slice.primary.cta_button[0].spans[0].data.target || '_self' : '' ;
            }
        },
    };
</script>

<style lang="scss">
@import "@scssMixins";

.gst-slice-cta-simple-content {
    .text-content {
        width: 55%;
    }
}

.gst-slice-primary-button {
    display: flex;
    height: min-content !important;
    padding: 12.5px 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0;
    max-width: 285px;
    white-space: break-spaces;
    flex-wrap: wrap;
    flex-direction: column;
    text-transform: capitalize;
}

@include mobile-only {
    .gst-slice-cta-simple-content {
        .text-content {
            width: 100%;
        }
    }

    .gst-slice-primary-button {
        width: 100%;
        max-width: unset;
    }
}
</style>
