<template>
    <BaseSlice
        :slice-type="slice.slice_type"
        :slice-index="slice.index"
        class="gst-slice-navbar"
        full-height>
        <div class="gst-slice-navbar__content d-flex flex-column align-center justify-center justify-md-start flex-md-row-reverse"
            :class="{'gst-slice__content--width-100': isMobile }">
            <div class="d-flex justify-center align-center mb-md-0"
                :class="{'my-2': isCtaButtonShown && isMobile }">
                <BaseButton
                    v-if="isCtaButtonShown"
                    class="gst-slice-primary-button"
                    :placeholder="ctaButtonPlaceholder"
                    :href="href"
                    :target="target"
                    @click="$emit('click')" />
            </div>
            <div ref="swiperContent"
                class="flex-grow-1 d-flex gst-slice-navbar__tabs justify-center align-md-center mb-2"
                :class="{'mt-2' : isCtaButtonShown && isMobile }">
                <button v-if="!isReachStart" class="d-flex gst-slice-navbar__tabs-arrow gst-slice-navbar__tabs-arrow-left mt-1" @click="scrollByArrows('left')">
                    <BaseIcon
                        symbol-id="icons--dropdown_arrow"
                        class="u-mouse-pointer u-rotate-90" />
                </button>
                <div
                    ref="swiper"
                    class="gst-slice-navbar__tabs-scrollable d-flex mt-md-1"
                    @scroll="scroll()">
                    <div v-for="(item, index) in sectionNames"
                        :key="index"
                        class="gst-slice-navbar__item">
                        <a :href="getTabId(item)">{{ item }}</a>
                    </div>
                </div>
                <button v-if="!isReachEnd" class="d-flex gst-slice-navbar__tabs-arrow gst-slice-navbar__tabs-arrow-right mt-1" @click="scrollByArrows('right')">
                    <BaseIcon
                        symbol-id="icons--dropdown_arrow"
                        class="u-mouse-pointer u-rotate-90-n " />
                </button>
            </div>
        </div>
    </BaseSlice>
</template>

<script>
    import get from 'lodash/get';
    import debounce from 'lodash/debounce';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'Navbar',
        components: {
            BaseButton,
            BaseIcon,
            BaseSlice
        },
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                sectionNames: [ ],
                isReachStart: true,
                isReachEnd: true,
            };
        },
        computed: {
            isCtaButtonShown() {
                return this.slice.primary.show_cta_button;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            scrollSize() {
                const swiperEl = this.$refs.swiper;
                const tabs = swiperEl.querySelectorAll( 'div' );

                return  swiperEl.offsetWidth / tabs.length;
            },
            ctaButtonPlaceholder() {
                const placeholderText = get( this.slice, 'primary.cta_button[0].text' );
                return  placeholderText ? placeholderText : '';
            },
            href() {
                return  this.slice.primary.cta_button[0].spans.length > 0 ? this.slice.primary.cta_button[0].spans[0].data.url : '';
            },
            target() {
                return this.slice.primary.cta_button[0].spans.length > 0 ? this.slice.primary.cta_button[0].spans[0].data.target || '_self' : '' ;
            }
        },
        methods: {
            getTabId( tab ) {
                return '#' + tab.replace( ' ', '_' );
            },
            totalTabsWidth() {
                let totalTabsWidth = 0;

                this.$nextTick( () => {
                    document.getElementsByClassName( 'gst-slice-navbar__item' ).forEach( ( item )=> {

                        let itemMarginLeft =   window.getComputedStyle( item ).marginLeft  ?  parseInt( window.getComputedStyle( item ).marginLeft.slice( 0, 2 ) ) : 0 ;
                        let itemMarginRight =  window.getComputedStyle( item ).marginRight  ?  parseInt( window.getComputedStyle( item ).marginRight.slice( 0, 2 ) ) : 0 ;
                        totalTabsWidth = totalTabsWidth + item.offsetWidth + itemMarginRight + itemMarginLeft ;
                    } );
                    if ( this.$refs.swiperContent.offsetWidth < totalTabsWidth ) {
                        this.isReachEnd = false;
                    } else {
                        this.isReachEnd = true;
                    }
                } );
            },
            scroll: debounce( function ( ) {
                const swiperEl = this.$refs.swiper;

                if ( swiperEl ) {
                    this.isReachStart = swiperEl.scrollLeft <= 16;
                    this.isReachEnd = ( swiperEl.scrollWidth - swiperEl.scrollLeft - swiperEl.offsetWidth ) < 1;
                }
            }, 50 ),
            scrollByArrows( direction ) {
                const swiperEl = this.$refs.swiper;

                if( direction === 'left' && this.isReachStart!== 0  ) {
                    swiperEl.scrollLeft -= this.scrollSize;
                } else if( direction === 'right' && this.isReachEnd!== 0  ) {
                    swiperEl.scrollLeft += this.scrollSize;
                }
            }
        },
        created()  {
            window.addEventListener( 'resize', this.totalTabsWidth );
        },
        destroyed()  {
            window.removeEventListener( 'resize', this.totalTabsWidth );
        },
        mounted() {
            let sections =  document.querySelectorAll( 'section[data-gst-nav = "true"]' );
            sections.forEach( section => {
                this.sectionNames.push( section.getAttribute( 'id' ) );
            } );
            this.totalTabsWidth();
        }
    };
</script>


<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-slice-navbar {
    position: sticky;
    top: 0;
    z-index: $z-index-header + 1;
    -webkit-box-shadow: 0 2px 9px rgba( 0, 0, 0, 0.2 ), 0 15px 24px rgba( 0, 0, 0, 0.1 );
    box-shadow: 0 2px 9px rgba( 0, 0, 0, 0.2 ), 0 15px 24px rgba( 0, 0, 0, 0.1 );

    .gst-slice-navbar__content {
        padding-top: 8px;
        padding-bottom: 8px;

        .gst-slice-navbar__tabs {
            position: relative;

            .gst-slice-navbar__tabs-arrow {
                position: absolute;
                height: 20px;
                width: 44px;

                svg {
                    position: absolute;
                    top: 0;
                }
            }

            .gst-slice-navbar__tabs-arrow-right {
                right: 0;
                background: linear-gradient( to right, rgba( 250, 255, 255, 0.5 ) 10%, rgba( 255, 255, 255, 1 ) 20% );

                svg {
                    right: 0;
                }
            }

            .gst-slice-navbar__tabs-arrow-left {
                left: 0;
                background: linear-gradient( to left, rgba( 255, 255, 255, 0.5 ) 10%, rgba( 255, 255, 255, 1 ) 20% );

                svg {
                    left: 0;
                }
            }
        }

        .gst-slice-navbar__item {
            width: fit-content;
            margin: 0 16px;

            a {
                color: #121212 !important;
            }
        }
    }
}

@include desktop-only {
    .gst-slice-navbar {
        .gst-slice-navbar__content {
            height: 50px;

            .gst-slice-navbar__item:first-of-type {
                margin-left: 0;
            }

            .gst-slice-navbar__tabs {
                width: 70%;

                .gst-slice-navbar__tabs-scrollable {
                    width: 100%;
                    overflow: hidden;
                }
            }
        }
    }
}

@include mobile-only {
    .gst-slice-navbar {
        .gst-slice-navbar__content {
            padding-left: 0;

            .gst-slice-navbar__tabs {
                width: 100%;

                .gst-slice-navbar__tabs-scrollable {
                    margin-top: 2px;
                    overflow: hidden;
                }
            }
        }

        .gst-slice-primary-button {
            width: 320px;
        }
    }
}
</style>