<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-faq pb-2">
        <PrismicRichText :field="slice.primary.title" class="gst-slice__title" />
        <DropdownList :items="slice.items" :separator="true" />
    </BaseSlice>
</template>

<script>
    import DropdownList from './components/DropdownList.vue';
    import NavMixin from './mixins/NavMixin.js';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'Faq',
        components: {
            DropdownList,
            BaseSlice
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        }
    };
</script>