<template>
    <div
        class="gst-slice-text-clamp overflow-hidden"
        :class="{
            'gst-slice-text-clamp--clamped': clamped
        }"
        :style="style">
        <slot></slot>
        <div v-if="clamped" class="gst-slice-text-clamp__fade"></div>
    </div>
</template>

<script>
    import {
        addListener as addListenerResize,
        removeListener as removeListenerResize } from 'resize-detector';

    export default {
        name: 'TextHeightClamp',
        props: {
            showAll: {
                type: Boolean,
                default: false
            },
            expandable: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'clamp'
        ],
        data( ) {
            return {
                clamped: false
            };
        },
        computed: {
            style( ) {
                if ( this.showAll && this.expandable ) {
                    return 'height: auto; max-height: none;';
                }
                return null;
            }
        },
        watch: {
            'style': {
                handler( ) {
                    this.$nextTick( this.checkOverflow );
                },
                immediate: true
            }
        },
        methods: {
            isClamped( ) {
                return !!( this.$el && this.$el.scrollHeight > this.$el.offsetHeight );
            },
            checkOverflow( ) {
                this.clamped = this.isClamped( );
                this.$emit( 'clamp', this.clamped );
            }
        },
        mounted( ) {
            this.$nextTick( this.checkOverflow );
            addListenerResize( this.$el, this.checkOverflow );
        },
        destroyed( ) {
            removeListenerResize( this.$el, this.checkOverflow );
        },
    };
</script>