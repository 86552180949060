export default {
    computed: {
        navigationAttributes ( ) {
            if ( this.slice.primary.nav ) {
                return {
                    id: this.slice.primary.nav.split( ' ' ).join( '_' ),
                    'data-gst-nav': true
                };
            }
            return null;
        }
    }
};