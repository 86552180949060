<template>
    <div v-resize="setSwiperPaddingDebounced" class="gst-swiper">
        <DataLoading v-if="loading" class="mt-5 mb-5 pt-5 pb-5" />
        <template v-else>
            <NavigationButton
                v-show="showNavigationButtons && !isReachStart"
                ref="previousButton"
                class="gst-slide-swiper__navigation gst-slide-swiper__navigation-left"
                orientation="left"
                @click="goToPreviousSlides" />
            <div
                ref="swiper"
                d-flex
                flex-row
                class="d-flex flex-row gst-swiper__content u-hide-scrollbar"
                :class="disableXAxisScroll ? 'gst-swiper__x-scroll--disabled': 'gst-swiper__x-scroll--enabled'"
                :style="{ ...swiperPaddings }"
                @scroll="setSwiperNavigationDebounced"
                @resize="setSwiperNavigationDebounced">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="flex-shrink-0"
                    :style="{
                        width: cardWidth+'px',
                    }">
                    <slot name="item" :item="item" :index="index">
                    </slot>
                </div>
            </div>
            <NavigationButton
                v-show="showNavigationButtons && !isReachEnd"
                ref="nextButton"
                class="gst-slide-swiper__navigation gst-slide-swiper__navigation-right"
                @click="goToNextSlides" />
        </template>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import NavigationButton from './NavigationButton';

    export default {
        name: 'Swiper',
        components: {
            DataLoading,
            NavigationButton
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.similarAttractions'
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: null
            },
            cardWidth: {
                type: Number,
                default: 123
            },
            cardsToScroll: {
                type: Number,
                default: 1
            },
            cardsPerScreen: {
                type: String,
                default: 'auto'
            },
            navigation: {
                type: Boolean,
                default: true
            },
            center: {
                type: Boolean,
                default: true
            },
            scrollLeftGutter: {
                type: Number,
                default: 4
            },
            disableXAxisScroll: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                isReachStart: true,
                isReachEnd: false,
                swiperPaddings: { }
            };
        },
        computed: {
            showNavigationButtons( ) {
                return this.navigation && !( this.isReachStart && this.isReachEnd );
            },
            cardWidthForScroll( ) {
                return this.cardsToScroll * this.cardWidth;
            }
        },
        watch: {
            'center': function( ) {
                this.setSwiperPaddingDebounced( );
            },
            'loading': {
                handler: function ( value ) {
                    if ( !value ) {
                        this.$nextTick( ( ) => {
                            this.addScroll( );
                        } );
                    }
                },
                immediate: true
            }
        },
        methods: {
            goToPreviousSlides( ) {
                const swiperEl = this.$refs.swiper;
                const cardWidthForScroll = this.cardWidthForScroll;
                const scrollLeft = swiperEl.scrollLeft;

                const scrollUntilPreviousInCards = ( scrollLeft / cardWidthForScroll ) - Math.floor( scrollLeft / cardWidthForScroll ) * cardWidthForScroll;

                if ( scrollUntilPreviousInCards < this.scrollLeftGutter ) {
                    swiperEl.scrollBy( -( this.cardWidthForScroll * this.cardsToScroll ), 0 );
                } else {
                    const scrollPastTheCurrentCard = scrollLeft - Math.floor( scrollLeft / cardWidthForScroll ) * this.cardWidthForScroll;

                    swiperEl.scrollBy( -( scrollPastTheCurrentCard ), 0 );
                }
            },
            goToNextSlides( ) {
                const swiperEl = this.$refs.swiper;
                const cardWidthForScroll = this.cardWidthForScroll;
                const scrollLeft = swiperEl.scrollLeft;

                const scrollUntilNextInCards = cardWidthForScroll - ( ( scrollLeft / cardWidthForScroll ) - Math.floor( scrollLeft / cardWidthForScroll ) ) * cardWidthForScroll;

                if ( scrollUntilNextInCards < 1 ) {
                    swiperEl.scrollBy( this.cardWidthForScroll * this.cardsToScroll + scrollUntilNextInCards, 0 );
                } else {
                    const scrollPastTheCurrentCard = scrollLeft - Math.floor( scrollLeft / cardWidthForScroll ) * this.cardWidthForScroll;

                    swiperEl.scrollBy( this.cardWidthForScroll - scrollPastTheCurrentCard, 0 );
                }

            },
            setSwiperNavigationDebounced: debounce( function ( ) {
                const swiperEl = this.$refs.swiper;

                if ( swiperEl ) {
                    this.isReachStart = swiperEl.scrollLeft <= this.scrollLeftGutter;
                    this.isReachEnd = ( swiperEl.scrollWidth - swiperEl.scrollLeft - swiperEl.offsetWidth ) < 1;
                }
            }, 50 ),
            setSwiperPaddingDebounced: debounce( function ( ) {
                const swiperEl = this.$refs.swiper;
                this.swiperPaddings = { };

                if ( swiperEl ) {
                    setTimeout( () => {
                        const { cardWidth } = this;
                        const swiperElBounds = swiperEl.getBoundingClientRect();

                        if ( this.center ) {
                            const paddingSize = `${swiperElBounds.width / 2 - cardWidth / 2}px`;
                            this.swiperPaddings = {
                                paddingLeft: paddingSize,
                                paddingRight: paddingSize,
                            };
                        }
                    }, 5 );
                }
            }, 50 ),
            addScroll( ) {
                if ( this.$refs.swiper ) {
                    this._swiper = this.$refs.swiper;
                    this._swiper.addEventListener(
                        'scroll',
                        this.preventScroll,
                        { passive: false }
                    );
                }
            },
            removeScroll( ) {
                this._swiper && this._swiper.removeEventListener( 'scroll', this.preventScroll );
            },
            preventScroll( e ) {
                e.preventDefault();
                e.stopPropagation();

                return false;
            }
        },
        mounted( ) {
            this.setSwiperNavigationDebounced( );
            this.setSwiperPaddingDebounced( );
            this.addScroll( );
        },
        destroyed( ) {
            window.removeEventListener( 'resize', this.setSwiperNavigationDebounced );
            window.removeEventListener( 'resize', this.setSwiperPaddingDebounced );
            this.removeScroll( );
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-swiper {
        position: relative;
        background-color: transparent;

        .gst-swiper__content {
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
        }

        .gst-swiper__x-scroll--disabled {
            overflow: hidden hidden;
        }

        .gst-swiper__x-scroll--enable {
            overflow: auto hidden;
        }

        .gst-slide-swiper__navigation {
            position: absolute;
            top: 200px;
            z-index: 1;
        }

        .gst-slide-swiper__navigation-left {
            left: 0;
        }

        .gst-slide-swiper__navigation-right {
            right: 0;
        }
    }
</style>
