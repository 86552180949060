<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" class="gst-slice-banner-image">
        <template v-slot:content>
            <PrismicImage :field="imageField" class="gst-slice__content--width-100" :style="imageStyles" />
        </template>
    </BaseSlice>
</template>

<script>
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'BannerImage',
        components: { BaseSlice },
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        computed: {
            isMobile( ) {
                return this.$vuetify.breakpoint.smAndDown;
            },
            imageField( ) {
                if ( this.isMobile ) {
                    return this.slice.primary.image.mobile;
                }
                return this.slice.primary.image;
            },
            imageStyles( ) {
                if ( this.isMobile && this.slice.primary.height ) {
                    return {
                        height: `${this.slice.primary.height_mobile}px`
                    };
                }
                if ( !this.isMobile && this.slice.primary.height ) {
                    return {
                        height: `${this.slice.primary.height}px`
                    };
                }
                return { };
            },
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-slice-banner-image {
    img {
        display: block;
        height: auto;
        object-fit: cover;
    }
}
</style>
