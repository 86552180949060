export default {
    methods: {
        updateDocumentTitle( value ) {
            if ( value && !this._routeLeave ) {
                document.title = value.substring( 0, 155 );
            }
        }
    },
    beforeRouteLeave( to, from, next ) {
        next( );
        this._routeLeave = true;
    }
};