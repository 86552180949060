<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" :full-width="false" :full-height="true" class="gst-slice-event-banner" :style="$vuetify.breakpoint.mdAndUp ? style: styleMobile">
        <div class="u-position-relative u-height-100  d-flex flex-column justify-end">
            <PrismicImage v-if="fields.logo_image.url" :field="fields.logo_image" class="gst-slice-event-banner__logo" />
            <div class="gst-slice-event-banner__event">
                <div class="d-flex gst-slice-event-banner__event-info d-flex flex-column flex-md-row align-items-center mb-4">
                    <div v-if="fields.date" class="d-flex mb-sm-4">
                        <BaseIcon symbol-id="icons--calendar" class="mr-2" />
                        <PrismicRichText :field="fields.date" class="gst-slice-event-banner__rich-text" />
                    </div>
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="gst-slice-event-banner__vertical-line mr-2 ml-2"></div>
                    <div v-if="fields.location" class="d-flex">
                        <BaseIcon symbol-id="icons--location" class="mr-2" />
                        <PrismicRichText :field="fields.location" class="gst-slice-event-banner__rich-text" />
                    </div>
                </div>
                <VClamp
                    autoresize
                    :max-lines="3"
                    class="gst-slice-event-banner__title">
                    {{ eventTitle }}
                </VClamp>
                <BaseButton
                    v-if="context.eventId"
                    color="rgba(255,255,255,0.16)"
                    class="gst-event-info__button d-flex align-center mt-2"
                    @click="openEventInfoModal">
                    <BaseIcon symbol-id="icons--info_round_italic" class="mr-2" />
                    <div>Event Info</div>
                </BaseButton>
            </div>
        </div>
    </BaseSlice>
</template>

<script>
    import VClamp from 'vue-clamp';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'HeroBanner',
        components: {
            BaseIcon,
            BaseButton,
            VClamp,
            BaseSlice
        },
        props: {
            slice: {
                type: Object,
                default: null
            },
            context: {
                type: Object,
                default: null
            }
        },
        computed: {
            fields( ) {
                return this.slice.primary;
            },
            style( ) {
                return {
                    backgroundImage: `url( ${this.slice.primary.background_image.url } )`
                };
            },
            styleMobile( ) {
                return {
                    backgroundImage: `url( ${this.slice.primary.mobile_background.url } )`
                };
            },
            eventTitle() {
                return this.fields.title[0].text;
            }
        },
        methods: {
            openEventInfoModal( ) {
                this.$router.push( {
                    name: 'eventInfo',
                    params: {
                        id: this.context.eventId
                    }
                } );
            },
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice-event-banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>