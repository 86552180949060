<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-location pb-2" content-class="gst-slice-location__content">
        <PrismicRichText :field="slice.primary.title" class="gst-slice__title gst-slice-location__title" />
        <div class="gst-slice-location__items-wrapper d-flex">
            <PrismicRichText :field="slice.primary.subtitle" class="gst-slice__title gst-slice-location__subtitle" />
            <div class="gst-slice-location__item-wrapper d-flex align-center mr-8 ml-4">
                <div>
                    <PrismicImage :field="slice.items[0].image" class="gst-slice-location__image mr-3" />
                </div>
                <div class="gst-slice-location__content-wrapper d-flex flex-column justify-center">
                    <PrismicRichText
                        :field="slice.items[0].subtitle"
                        class="gst-slice__text gst-slice-location__content-subtitle" />
                    <PrismicRichText
                        :field="slice.items[0].content"
                        class="gst-slice__text gst-slice-location__content-text mt-1" />
                    <div class="gst-slice-location__modal-opener">
                        <PrismicRichText
                            :field="slice.items[0].link"
                            class="gst-slice__text gst-slice-location__link-directions mt-2" />
                    </div>
                </div>
            </div>
            <div v-if="slice.items[1]" class="gst-slice-location__vertical-line mr-2 ml-2"></div>
            <div v-if="slice.items[1]" class="gst-slice-location__item-wrapper d-flex align-center mr-8 ml-8">
                <div>
                    <PrismicImage :field="slice.items[1].image" class="gst-slice-location__image mr-3" />
                </div>
                <div class="gst-slice-location__content-wrapper d-flex flex-column justify-center">
                    <PrismicRichText
                        :field="slice.items[1].subtitle"
                        class="gst-slice__text gst-slice-location__content-subtitle" />
                    <div class="gst-slice-location__modal-opener" tabindex="0" @click="openMapModal" @keypress.enter="openMapModal">
                        <PrismicRichText
                            :field="slice.items[1].link"
                            class="gst-slice__text gst-slice-location__link-map mt-2" />
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="showModal" @close-modal="showModal = false">
            <PrismicImage :field="slice.primary.modal_image" class="gst-slice-location__image" />
        </Modal>
    </BaseSlice>
</template>

<script>
    import Modal from './components/Modal.vue';
    import NavMixin from './mixins/NavMixin.js';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'Location',
        components: {
            Modal,
            BaseSlice
        },
        mixins: [
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                showModal: false
            };
        },
        methods: {
            openMapModal() {
                this.showModal = true;
            },
            closeMapModal() {
                this.showModal = false;
            }
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice.gst-slice-location {
        h3 {
            word-break: break-word;
        }
    }

    .gst-slice-location__items-wrapper .gst-slice-location__item-wrapper {
        width: 60%;
    }

    .gst-slice-location__items-wrapper .gst-slice-location__item-wrapper:last-child {
        width: 40%;
    }

    @include mobile-only {
        .gst-slice-location__items-wrapper .gst-slice-location__item-wrapper {
            width: 95%;
        }

        .gst-slice-location__items-wrapper .gst-slice-location__item-wrapper:last-child {
            width: 95%;
        }
    }

</style>
