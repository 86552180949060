<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-tabs-content pb-2">
        <PrismicRichText :field="slice.primary.title" class="gst-slice-tabs-content__tile" />
        <div class="gst-slice-tabs-content__tabs">
            <BaseButton
                v-for="( item, key ) in slice.items"
                :key="key"
                class="gst-slice-tabs-content__tab"
                :class="{
                    'gst-slice-tabs-content__tab--active': currentTab === item
                }"
                @click="setTab(item)">
                <PrismicText :field="item.tab" />
            </BaseButton>
        </div>
        <TextHeightClamp
            class="gst-slice__text"
            :show-all="!contentClamped"
            :expandable="showButtonSeeMore"
            @clamp="onClampDo">
            <PrismicRichText
                :field="currentTab.tab_content" />
        </TextHeightClamp>
        <SeeMoreButton
            v-if="isButtonSeeMoreActive"
            class="gst-slice__footer"
            :see-more="contentClamped"
            @click="contentClamped = !contentClamped" />
    </BaseSlice>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import ClampMixin from './mixins/ClampMixin';
    import SeeMoreButton from './components/SeeMoreButton.vue';
    import TextHeightClamp from './components/TextHeightClamp.vue';
    import NavMixin from './mixins/NavMixin.js';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'TabContent',
        components: {
            BaseButton,
            SeeMoreButton,
            TextHeightClamp,
            BaseSlice
        },
        mixins: [
            ClampMixin,
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            },
            maxLines: {
                type: Number,
                default: 5
            },
            baseLineHeight: {
                type: Number,
                default: 40
            }
        },
        data() {
            return {
                currentTab: false,
                isFullHeight: false,
                itemExpanded: [ ],
                isButtonSeeMoreActive: false
            };
        },
        methods: {
            setTab( item ) {
                this.currentTab = item;
                this.isButtonSeeMoreActive = this.currentTab.show_see_more;
                if ( !this.isButtonSeeMoreActive ) {
                    this.contentClamped = false;
                }
            }
        },
        mounted( ) {
            this.currentTab = this.slice.items[ 0 ];
        }
    };
</script>


<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice-tabs-content {
        .gst-slice-text-clamp {
            max-height: 200px;
        }
    }
</style>
