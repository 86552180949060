<template>
    <div class="gst-slice gst-slice-footer">
        <div class="gst-slice__content gst-slice__content--width-100">
            <PrismicRichText :field="slice.primary.content" class="gst-slice-footer__text gst-slice__text" />
            <hr />
            <ManageCookiesButton class="gst-slice-footer__manage-cookies-button pb-4" />
            <PrismicRichText :field="slice.primary.rights" class="gst-slice-footer__text gst-slice__text" />
        </div>
    </div>
</template>

<script>
    import ManageCookiesButton from '@tenant/app/components/misc/ManageCookiesButton';

    export default {
        name: 'Footer',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.Footer'
        },
        components: {
            ManageCookiesButton
        },
        props: {
            slice: {
                type: Object,
                default: null
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice-footer {
        text-align: center;

        .gst-slice-footer__text {
            margin: 0 auto;
        }

        .gst-slice-footer__manage-cookies-button {
            line-height: 19px;
            font-size: font-size( 'l' );
            text-decoration: underline;
        }
    }
</style>


