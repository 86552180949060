<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-simple-content pb-2">
        <PrismicRichText :field="slice.primary.title" class="gst-slice-simple-content__title" />
        <div ref="wrapper" class="gst-slice-simple__wrapper">
            <PrismicRichText ref="content" :field="slice.primary.content" class="gst-slice-simple-content__artists" />
            <div v-if="!isSeeMoreOpen" class="gst-slice-simple__content-fade"></div>
        </div>
        <SeeMoreButton
            v-if="showButtonSeeMore"
            class="gst-slice-see-more-button mt-10"
            :see-more="!isSeeMoreOpen"
            @click="seeMore" />
    </BaseSlice>
</template>

<script>
    import SeeMoreButton from './components/SeeMoreButton.vue';
    import NavMixin from './mixins/NavMixin.js';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'SimpleContent',
        components: {
            SeeMoreButton,
            BaseSlice
        },
        mixins: [
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            },
            maxLines: {
                type: Number,
                default: 5
            },
            baseLineHeight: {
                type: Number,
                default: 40
            }
        },
        data() {
            return {
                showButtonSeeMore: false,
                isSeeMoreOpen: false,
            };
        },
        computed: {
            style() {
                return `line-height: ${this.baseLineHeight.toString()}px;`;
            }
        },
        methods: {
            seeMore( ) {
                this.isSeeMoreOpen = !this.isSeeMoreOpen;

                if ( !this.isSeeMoreOpen ) {
                    this.$refs.wrapper.style.height = `${this.baseLineHeight * this.maxLines}px`;
                } else {
                    this.$refs.wrapper.style.height = `auto`;
                }
            },
            showMoreButton( ) {
                this.$refs.content.querySelectorAll( 'p' ).forEach( ( item ) => {
                    item.style.lineHeight = `${this.baseLineHeight}px`;
                } );
                const totalLines = Math.ceil( this.$refs.content.offsetHeight / this.baseLineHeight );

                this.$refs.wrapper.style.height = `${this.baseLineHeight * this.maxLines}px`;
                this.showButtonSeeMore = totalLines > this.maxLines? true: false;
            },
        },
        mounted( ) {
            this.showMoreButton();
        }
    };
</script>
