<template>
    <div class="gst-event-marketing">
        <DataLoading v-if="loading" class="mt-5 mb-5 pt-5 pb-5" />
        <template v-else>
            <component :is="'style'">
                {{ style }}
            </component>
            <div class="slicezone">
                <SliceZone
                    v-if="!loading"
                    :slices="content.data.body"
                    :context="slicesContext"
                    :components="{
                        event_banner: EventBanner,
                        experience_sub: ExperienceSub,
                        banner_video: BannerVideo,
                        banner_image: BannerImage,
                        itinerary: Itinerary,
                        experience_details: ExperienceDetails,
                        simple_content: SimpleContent,
                        tabs_content: TabsContent,
                        location: Location,
                        location_sub: LocationSub,
                        faq: Faq,
                        info_with_image: InfoWithImage,
                        footer: Footer,
                        navbar: Navbar,
                        cta_simple_content: SimpleContentWithCtaButton,
                        section_header: SectionHeader,
                        spacer: Spacer,
                        gallery_carousel: GalleryCarousel,
                        form_embedded: FormEmbedded
                    }" />
            </div>
        </template>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import { LogService } from '@core/services';
    import ViewDocumentTitleMixin from '@tenant/app/mixins/ViewDocumentTitleMixin';
    import Footer from '@tenants/ticketmaster/app/slices/Footer';
    import GalleryCarousel from '@tenants/ticketmaster/app/slices/GalleryCarousel';
    import EventBanner from '@tenants/ticketmaster/app/slices/EventBanner';
    import ExperienceDetails from '@tenants/ticketmaster/app/slices/ExperienceDetails';
    import ExperienceSub from '@tenants/ticketmaster/app/slices/ExperienceSub';
    import BannerVideo from '@tenants/ticketmaster/app/slices/BannerVideo';
    import Itinerary from '@tenants/ticketmaster/app/slices/Itinerary';
    import BannerImage from '@tenants/ticketmaster/app/slices/BannerImage';
    import TabsContent from '@tenants/ticketmaster/app/slices/TabsContent';
    import {  addCounterOnSlices } from '@tenant/app/utils/prismicUtils';
    import SimpleContent from '@tenants/ticketmaster/app/slices/SimpleContent';
    import Faq from '@tenants/ticketmaster/app/slices/Faq';
    import InfoWithImage from '@tenants/ticketmaster/app/slices/InfoWithImage';
    import Navbar  from '@tenants/ticketmaster/app/slices/Navbar';
    import LocationSub from '@tenants/ticketmaster/app/slices/LocationSub';
    import Location from '@tenants/ticketmaster/app/slices/Location';
    import SectionHeader from '@tenants/ticketmaster/app/slices/SectionHeader';
    import SimpleContentWithCtaButton from '@tenants/ticketmaster/app/slices/SimpleContentWithCtaButton.vue';
    import Spacer from '@tenants/ticketmaster/app/slices/Spacer.vue';
    import FormEmbedded from '@tenants/ticketmaster/app/slices/FormEmbedded.vue';
    import { redirectToHome  } from '@tenant/app/utils/tenantUrlslUtils';

    export default {
        name: 'TheEventMarketing',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home'
        },
        components: {
            DataLoading,
        },
        mixins: [
            ViewDocumentTitleMixin
        ],
        data() {
            return {
                content: null,
                languages: null,
                Footer,
                EventBanner,
                ExperienceDetails,
                ExperienceSub,
                BannerVideo,
                BannerImage,
                TabsContent,
                SimpleContent,
                GalleryCarousel,
                Faq,
                InfoWithImage,
                Navbar,
                LocationSub,
                Location,
                Itinerary,
                SimpleContentWithCtaButton,
                SectionHeader,
                Spacer,
                FormEmbedded,
                loading:true
            };
        },
        computed: {
            ...mapGetters( {
                appLocale: 'appState/getLocale'
            } ),
            slicesContext( ) {
                return {
                    eventId: this.$route.params.id
                };
            },
            style( ) {
                return `
                    ${this.$prismic.asText( this.content.data.style )}
                `;
            },
            availableLanguages( ) {
                const alternativeLangs = this.content.alternate_languages.map( item => {
                    return item.lang;
                } );
                const langs = [
                    this.content.lang,
                    ...alternativeLangs
                ];
                return langs;
            },
            routeLang( ) {
                return this.$route.query.lang;
            }
        },
        watch: {
            appLocale: function ( newValue, oldValue ) {
                if ( newValue && newValue !== oldValue ) {
                    this.init( );
                }
            }
        },
        methods: {
            ...mapActions( {
                updateLangList: 'languages/updateLangList',
            } ),
            async getContent( lang ) {
                const config = lang ? { lang } : { };
                const id = this.$route.params.id;

                try {
                    return await this.$prismic.client.getByUID(
                        'event-marketing',
                        id.toLocaleLowerCase( ),
                        config
                    );
                } catch( e ) {
                    LogService.log( e.message );
                }
            },
            async init( ) {
                this.content = await this.getContent( this.appLocale );
                if ( !this.content ) {
                    //fallback to master language
                    this.content = await this.getContent( );
                }
                if ( this.content ) {
                    this.content.data.body = addCounterOnSlices( this.content );
                    this.updateLangList( this.availableLanguages );
                    this.loading = false;
                    const title = this.content.data.document_title;
                    if ( title ) {
                        this.updateDocumentTitle( this.$t( 'main:viewsTitle.theEventMarketing', { package: title, interpolation: { escapeValue: false } } ) );
                    }
                } else {
                    redirectToHome( );
                }
            }
        },
        mounted( ) {
            this.init( );
        },
        destroyed( ) {
            this.updateLangList( [] );
        },
    };
</script>

<style lang="scss">
@import "@scssMixins";

$slice-light-background: #FFFFFF;
$slice-light-section-background: #FFFFFF;
$slice-light-section-secondary-background: #F6F6F6;
$slice-light-text-color: #121212;
$slice-light-text-color-2: #646464;
$slice-light-link-color: #024DDF;
$slice-light-button-cta-disabled-background: #646464;
$slice-light-button-cta-disabled-color: #FFFFFF;
$slice-light-button-cta-color: #FFFFFF;
$slice-light-button-cta-background: #024DDF;
$slice-light-button-background: #646464;
$slice-light-button-color: #121212;
$slice-light-button-hover-background: #646464;
$slice-light-button-hover-color: #121212;
$slice-light-button-active-background: #646464;
$slice-light-button-active-color: #121212;
$slice-light-border: #646464;
$slice-light-dropdown-border: #EBEBEB;
$slice-light-dropdown-row-hover: #F6F6F6;
$slice-light-dropdown-text-color: #646464;
$slice-light-image-border: #FFFFFF;
$slice-light-location-vertical-line: #EBEBEB;
$slice-light-modal-button-background: #D6D6D6;

$slice-dark-background: #000000;
$slice-dark-section-background: #000000;
$slice-dark-text-color: #FFFFFF;
$slice-dark-link-color: #024DDF;
$slice-dark-button-background: #646464;
$slice-dark-button-color: #121212;
$slice-dark-button-hover-background: #646464;
$slice-dark-button-hover-color: #121212;
$slice-dark-button-active-background: #646464;
$slice-dark-button-active-color: #121212;
$slice-dark-border: #121212;

.gst-event-marketing {
    font-family: "Averta Std", sans-serif !important;

    .gst-slice {
        width: 100%;
        background-color: $slice-light-background;
        color: $slice-light-text-color;

        .gst-slice__content {
            padding: 64px 16px 96px;
            margin: 0 auto;
            max-width: 1230px; //1198 + padding 16 16
        }

        .gst-slice__content--width-100 {
            width: 100%;
            padding-right: unset;
            padding-left: unset;
            max-width: unset;
        }

        .gst-slice__content--height-100 {
            height: 100%;
            padding-top: unset;
            padding-bottom: unset;
        }

        .gst-slice__text {
            max-width: 1198px;
        }

        .gst-slice-see-more-button {
            display: flex;
            padding: 17.5px 28px;
            border: 1px solid $slice-dark-button-color;
            background-color: $slice-dark-button-color !important;
            color: $slice-dark-text-color;
            font-size: 16px;
            font-weight: 400;
            text-transform: none;
            text-transform: lowercase;
            border-radius: 50px;
            min-width: 165px;

            svg {
                transition: all ease 0.5s;

                .gst-svg-icon {
                    fill: $slice-dark-text-color;
                }
            }
        }

        .gst-slice-see-more-button:hover {
            background: transparent !important;
            color: $slice-dark-button-hover-color;

            svg {
                .gst-svg-icon {
                    fill: $slice-dark-button-hover-color;
                }
            }

            .gst-sprite-ticketmaster-gst-svg-icon {
                fill: $slice-dark-text-color;
            }
        }

        .gst-slice-navigation-button {
            background: $slice-light-button-cta-background !important;

            svg {
                .gst-svg-icon {
                    fill: $slice-light-button-cta-color;
                }
            }

            &.gst-btn--disabled {
                background: $slice-light-button-cta-disabled-background !important;

                svg {
                    .gst-svg-icon {
                        fill: $slice-light-button-cta-disabled-color;
                    }
                }
            }
        }

        .gst-slice-text-clamp {
            position: relative;

            .gst-slice-text-clamp__fade {
                position: absolute;
                bottom: 0;
                height: 100px;
                width: 100%;
                background: linear-gradient( transparent, $slice-light-section-background 100% );
            }
        }

        .gst-slice-primary-button {
            background: $slice-light-button-cta-background !important;
            color: white !important;
        }

        h1 {
            line-height: 48px;
            font-size: 40px;
            font-weight: 800;
            font-style: normal;
        }

        h2 {
            line-height: 24px;
            padding-bottom: 8px;
            margin-bottom: 32px;
            color: $slice-light-text-color;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            border-bottom: 4px solid transparent;
        }

        h2::after {
            display: block;
            height: 4px;
            width: 31px;
            margin-top: 5px;
            background: $slice-dark-background;
            content: '';
        }

        h3 {
            line-height: 21px;
            color: $slice-light-text-color;
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
        }

        h4 {
            line-height: 17px;
            color: $slice-light-text-color;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
        }

        h5 {
            line-height: 17px;
            color: $slice-light-text-color-2;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
        }

        h6 {
            line-height: 19px;
            color: $slice-light-text-color-2;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
        }

        p {
            line-height: 19px;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
        }

        hr {
            border-top: 1px solid #EBEBEB;
        }

        ul {
            line-height: 16px;

            li {
                margin-bottom: 8px;
            }
        }

        a {
            color: $slice-light-link-color;
            font-size: 16px;
            font-weight: 400;
        }

        link {
            line-height: 100%;
            color: $slice-light-link-color;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
        }
    }

    .gst-slice-event-banner {
        height: 471px;
        background-color: $slice-light-text-color;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .gst-slice-event-banner__logo {
            position: absolute;
            top: 41px;
            right: 0;
            height: 250px;
            width: 250px;
            filter:
                drop-shadow( 0 86px 34px rgba( 20, 20, 20, 0.02 ) )
                drop-shadow( 0 48px 29px rgba( 20, 20, 20, 0.08 ) )
                drop-shadow( 0 21px 21px rgba( 20, 20, 20, 0.14 ) )
                drop-shadow( 0 5px 12px rgba( 20, 20, 20, 0.16 ) )
                drop-shadow( 0 0 0 rgba( 20, 20, 20, 0.17 ) );
        }

        .gst-slice__content {
            padding-bottom: 32px;
            color: $slice-dark-text-color;

            .gst-slice-event-banner__event {
                .gst-slice-event-banner__title {
                    width: 75%;
                    font-size: 40px;
                    font-weight: 800 !important;
                }

                .gst-slice-event-banner__rich-text {
                    height: 14px;
                }

                .gst-slice-event-banner__vertical-line {
                    height: 22px;
                    border-left: 1px solid $slice-dark-text-color;
                }

                .gst-event-info__button {
                    display: none !important;
                    //this line must be deleted if it is decided to keep this button
                    color: $slice-dark-text-color;
                }

                .gst-svg-icon {
                    fill: white;
                }
            }
        }
    }

    .gst-slice-simple-content {
        .gst-slice-simple__content-fade {
            position: absolute;
            bottom: 0;
            height: 100px;
            width: 100%;
            background: linear-gradient( transparent, #FFFFFF 100% );
        }

        .gst-slice__content {
            .gst-slice-simple-content__artists {
                color: #121212;
                overflow: hidden;
                max-width: 812px;
            }

            .gst-slice-simple__wrapper {
                position: relative;
                overflow: hidden;
            }
        }
    }

    .gst-slice-cta-simple-content.gst-slice__content {
        padding: 40px 16px;
    }

    .gst-slice-tabs-content {
        .gst-slice__content {
            .gst-slice-tabs-content__text {
                line-height: 40px;
            }

            .gst-slice-tabs-content__tabs {
                margin-bottom: 48px;

                .gst-btn {
                    padding: 15px 33px;
                    border: 1px solid #646464;
                    border-radius: 0;

                    span {
                        font-size: 18px;
                        font-weight: 600;
                        border-style: none;
                        text-transform: capitalize;
                    }
                }

                .gst-btn.gst-slice-tabs-content__tab--active {
                    background: black;

                    span {
                        color: white;
                    }
                }

                .gst-btn:hover {
                    background: black;
                    transition: all 0.7s;

                    span {
                        color: white;
                    }
                }

                .gst-btn:nth-child(1) {
                    border-color: #646464;
                    border-style: solid;
                    border-width: 1px 0 1px 1px;
                    border-radius: 4px 0 0 4px;
                }

                .gst-btn:nth-child(3) {
                    border-color: #646464;
                    border-style: solid;
                    border-width: 1px 1px 1px 0;
                    border-radius: 0 4px 4px 0;
                }
            }

            .gst-slice__footer {
                margin-top: 16px;
            }
        }
    }

    .gst-slice-experience-sub {
        min-height: 400px;

        .gst-slice__content {
            .gst-slice-experience-sub__info {
                padding-right: 60px;
                max-width: 555px;
            }
        }
    }

    .gst-slice-location {
        background: $slice-light-section-secondary-background;

        .gst-slice-location__content {
            .gst-slice-location__vertical-line {
                height: 94px;
                border-left: 1px solid $slice-light-location-vertical-line;
            }

            .gst-slice-location__content-wrapper {
                .gst-slice-location__content-text {
                    p {
                        margin-bottom: 0;
                    }
                }

                .gst-slice-location__modal-opener {
                    cursor: pointer;

                    .gst-slice-location__link-map {
                        color: $slice-light-link-color;
                    }
                }
            }

            .gst-slice-location__subtitle {
                width: 200px;
            }

            .gst-slice-location__image {
                border: solid $slice-light-image-border 4px;
                border-radius: 50%;
                filter: drop-shadow( 0 4px 23px rgba( 0, 0, 0, 0.25 ) );
            }
        }
    }

    .gst-slice-location-sub {
        .gst-slice-location-sub__title {
            h2 {
                padding-top: 8px;
                padding-right: 16px;
                border: unset;
                font-size: 18px;
                font-weight: 600;
                white-space: nowrap;
                min-width: 200px;
            }
        }

        .gst-slice-location-sub__content {
            display: flex;

            .gst-slice-location-sub__items-wrapper {
                width: 100%;
            }
        }
    }

    .gst-slice-faq {
        .gst-slice__content {
            div:nth-child(1) {
                padding-top: 0 !important;
            }
        }
    }

    .gst-slice.gst-slice-itinerary {
        background-color: $slice-dark-section-background;

        .gst-slice__content {
            .gst-slice__content-dark {
                color: $slice-dark-text-color;

                h2 {
                    border-bottom: 4px solid transparent;
                }

                h2::after {
                    display: block;
                    height: 4px;
                    width: 31px;
                    margin-top: 5px;
                    background: $slice-light-background;
                    content: '';
                }

                p {
                    margin-bottom: 32px;
                    max-width: 712px;
                }
            }

            .gst-slice-itinerary__card {
                background-color: $slice-light-section-background;

                .gst-slice-itinerary__card-content {
                    a:last-of-type {
                        margin-top: 4px;
                    }
                }
            }
        }

        .gst-slice-drawer {
            .gst-slice-drawer__footer {
                height: 72px;
                padding: 0 16px;
                box-shadow: 0 2px 20px rgba( 0, 0, 0, 0.12 );

                svg {
                    .gst-svg-icon {
                        fill: $slice-light-button-cta-background;
                    }
                }
            }
        }
    }

    .gst-slice-dropdown-list {
        .gst-slice-dropdown__content {
            width: 70%;
            color: $slice-light-dropdown-text-color;

            .gst-slice__text {
                padding-bottom: 24px !important;
            }
        }

        .gst-slice-dropdown__title {
            width: 100%;
            justify-content: space-between;
        }

        .gst-slice-dropdown__row:hover {
            background: $slice-light-dropdown-row-hover;
            cursor: pointer;
        }

        .gst-slice-dropdown__row {
            hr {
                border-top: 1px solid $slice-light-dropdown-border;
            }

            .gst-slice-dropdown__arrow {
                transition: all 0.2s linear;
            }
        }
    }

    .gst-slice-modal {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba( 18, 18, 18, 0.65 );
        justify-content: center;
        align-items: center;

        .gst-slice-modal__button-wrapper {
            position: relative;
            top: -19px;
            right: -101px;
            transform: translate( 100%, 0 );
            cursor: pointer;
        }

        .gst-slice-modal__button-close {
            position: relative;
            height: 40px;
            width: 40px;
            background-color: $slice-light-modal-button-background;
            border-radius: 2px;
        }

        .gst-slice-modal__button-close::before,
        .gst-slice-modal__button-close::after {
            position: absolute;
            top: 20px;
            left: 12px;
            height: 2.5px;
            width: 16px;
            background-color: $slice-light-button-active-color;
            content: "";
        }

        .gst-slice-modal__button-close::before {
            transform: rotate( 45deg );
        }

        .gst-slice-modal__button-close::after {
            transform: rotate( -45deg );
        }
    }

    .gst-slice-gallery-carousel {
        .gst-slice__content {
            .gst-slice-gallery-carousel__swiper {
                .gst-slice-gallery-carousel__swiper-image {
                    height: 242px;
                    width: 287px;
                    margin: 8px;
                    cursor: pointer;
                }

                .gst-slice-navigation-button {
                    top: 108px;
                }
            }

            .gst-slice-gallery-carousel__one-image-swiper {
                position: relative;

                .one-image-swiper__control-prev,
                .one-image-swiper__control-next {
                    position: absolute;
                    top: 40%;
                    padding: 0 !important;
                    z-index: 1;
                }

                .one-image-swiper__control-next {
                    right: 0;
                }

                .v-carousel__controls {
                    position: unset;
                    background-color: white;
                    align-items: end;
                }

                .v-carousel__controls__item {
                    height: 8px;
                    width: 8px;
                    margin: 2px;
                    background-color: #BFBFBF;
                }

                .v-item--active {
                    background: $slice-light-button-cta-background;
                    opacity: 1;
                }
            }
        }
    }

    .gst-slice-footer {
        background-color: $slice-dark-background;
        color: $slice-dark-text-color;
        text-align: center;

        hr {
            margin-top: 60px;
            margin-bottom: 32px;
            border-top: 1px solid $slice-dark-border;
        }
    }

    .gst-slice-spacer {
        .gst-slice__content {
            padding: 0 !important;
        }
    }

    @include mobile-only {
        .gst-slice {
            .gst-slice__content {
                width: 100%;
                padding: 40px 16px;
            }

            .gst-slice__content--width-100 {
                width: 100% !important;
                padding-right: unset;
                padding-left: unset;
            }

            .gst-slice__content--height-100 {
                height: 100%;
                padding-top: unset;
                padding-bottom: unset;
            }
        }

        .gst-slice-footer__text {
            margin: 0 16px;
        }

        .gst-slice-event-banner {
            position: relative;
            height: 365px;

            .gst-slice-event-banner__logo {
                position: absolute;
                top: 16px;
                right: 16px;
                height: 50px;
                width: 50px;
            }

            .gst-slice__content {
                padding-bottom: 24px;
                color: $slice-dark-text-color;

                .gst-slice-event-banner__event {
                    .gst-slice-event-banner__title {
                        width: unset;
                    }

                    .gst-event-info__button {
                        color: $slice-dark-text-color;
                    }
                }
            }
        }

        .gst-slice-location-sub {
            padding: 40px 16px;

            .gst-slice-location-sub__content {
                display: unset;
            }
        }

        .gst-slice-location__content {
            .gst-slice-location__items-wrapper {
                flex-direction: column;

                .gst-slice-location__item-wrapper {
                    margin-top: 24px;
                    margin-left: 0 !important;
                }
            }

            .gst-slice-location__vertical-line {
                display: none;
            }
        }
    }
}
</style>