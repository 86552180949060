var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gst-event-marketing"},[(_vm.loading)?_c('DataLoading',{staticClass:"mt-5 mb-5 pt-5 pb-5"}):[_c('style',{tag:"component"},[_vm._v(" "+_vm._s(_vm.style)+" ")]),_c('div',{staticClass:"slicezone"},[(!_vm.loading)?_c('SliceZone',{attrs:{"slices":_vm.content.data.body,"context":_vm.slicesContext,"components":{
                    event_banner: _vm.EventBanner,
                    experience_sub: _vm.ExperienceSub,
                    banner_video: _vm.BannerVideo,
                    banner_image: _vm.BannerImage,
                    itinerary: _vm.Itinerary,
                    experience_details: _vm.ExperienceDetails,
                    simple_content: _vm.SimpleContent,
                    tabs_content: _vm.TabsContent,
                    location: _vm.Location,
                    location_sub: _vm.LocationSub,
                    faq: _vm.Faq,
                    info_with_image: _vm.InfoWithImage,
                    footer: _vm.Footer,
                    navbar: _vm.Navbar,
                    cta_simple_content: _vm.SimpleContentWithCtaButton,
                    section_header: _vm.SectionHeader,
                    spacer: _vm.Spacer,
                    gallery_carousel: _vm.GalleryCarousel,
                    form_embedded: _vm.FormEmbedded
                }}}):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }