<template>
    <v-carousel
        v-model="activeSlide"
        class="gst-slice-image-carousel"
        :height="height"
        :show-arrows="false"
        :hide-delimiters="imagesToDisplay.length <= 1 && hideDelimiters"
        v-bind="$attrs"
        v-on="$listeners"
        @mouseenter.native="onCarouselMouseEnterDo"
        @mouseleave.native="onCarouselMouseLeaveDo">
        <template>
            <NavigationButton
                v-if="showNavigationButtons"
                ref="previousButton"
                :disabled="activeSlide === 0"
                class="gst-slice-image-carousel__navigation-left d-inline"
                orientation="left"
                @click="onCarouselClickPrevDo" />
            <NavigationButton
                v-if="showNavigationButtons"
                ref="previousButton"
                :disabled="activeSlide === imagesToDisplay.length - 1"
                class="gst-slice-image-carousel_navigation-right"
                @click="onCarouselClickNextDo" />
        </template>

        <v-carousel-item v-for="(image, index) in imagesToDisplay"
            :key="index">
            <BaseImage :src="image.src" :fallback-src="image.fallBack" :height="height" />
        </v-carousel-item>
        <v-carousel-item v-if="!imagesToDisplay.length">
            <BaseImage class="gst-room-card-variant-3__gallery__fallback" />
        </v-carousel-item>
    </v-carousel>
</template>

<script>
    import debounce from 'lodash/debounce';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import NavigationButton from './NavigationButton';

    export default {
        name: 'ImageCarousel',
        components: {
            BaseImage,
            NavigationButton
        },
        props: {
            images: {
                type: Array,
                default: new Array()
            },
            height: {
                type: Number,
                default: 160
            },
            hideDelimiters: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                activeSlide: 0,
                displayNavigation: false
            };
        },
        computed: {
            imagesToDisplay ( ) {
                return this.images.map( ( image ) => {
                    return {
                        src: image.url,
                        fallBack: ''
                    };
                } );
            },
            showNavigationButtons() {
                if( this.imagesToDisplay.length > 1 ) {
                    return this.displayNavigation;
                }
                return false;
            }
        },
        methods: {
            onCarouselMouseEnterDo: debounce( function( ) {
                this.displayNavigation = true;
            }, 50 ),
            onCarouselMouseLeaveDo: debounce( function( ) {
                this.displayNavigation = false;
            }, 50 ),
            onCarouselClickPrevDo () {
                if( this.activeSlide > 0 ) {
                    this.activeSlide--;
                }
            },
            onCarouselClickNextDo () {
                if( this.activeSlide < this.imagesToDisplay.length - 1 ) {
                    this.activeSlide++;
                }
            }
        },
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-slice-image-carousel {
    position: relative;
    width: 100%;
    max-height: 400px;

    .gst-slice-image-carousel__navigation-left,
    .gst-slice-image-carousel_navigation-right {
        position: absolute;
        top: calc( 50% - 12px );
        z-index: 1;
    }

    .gst-slice-image-carousel__navigation-left {
        left: theme-spacing( 0 );
    }

    .gst-slice-image-carousel_navigation-right {
        right: theme-spacing( 0 );
    }

    .v-carousel__controls {
        height: 22px;
        background-color: unset;
    }

    .v-carousel__controls__item {
        height: theme-spacing( 2 );
        width: theme-spacing( 2 );
        margin: 3px;

        .v-icon {
            height: theme-spacing( 3 );
            width: theme-spacing( 3 );
        }
    }

    .v-item--active {
        background: theme-color( 'white' );
        opacity: 1;
    }

    .gst-room-card-variant-3__gallery__fallback {
        height: 100%;
    }

    @include mobile-only {
        width: 100%;
    }
}
</style>
