<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" class="gst-slice-experience-details">
        <PrismicRichText :field="slice.primary.title" class="gst-slice-experience-details__title pb-md-2" />
        <div class="d-flex flex-column flex-md-row justify-md-space-between">
            <div class="gst-slice-experience-details__content mr-10">
                <div class="d-flex mb-2">
                    <PrismicRichText :field="slice.primary.hotel_name" class="mr-2" />
                    <PrismicImage :field="slice.primary.image_stars" />
                </div>
                <div class="gst-slice-experience-details__highlights">
                    <PrismicRichText :field="slice.primary.highlights" />
                </div>
                <a v-if="openDrawerTest"
                    href="#"
                    @click="onClickOpenDrawerDo($event);
                    ">
                    {{ openDrawerTest }}
                </a>
            </div>
            <div class="d-md-flex align-md-center">
                <div class="gst-slice-experience-details__price mr-4 mt-3 mb-2 mt-md-0 mb-md-0">
                    <PrismicRichText :field="slice.primary.price" class="d-flex d-md-block align-baseline" />
                </div>
                <BaseButton
                    :disabled="isDisabled"
                    :placeholder="slice.primary.cta_button[0].text"
                    class="gst-slice-primary-button"
                    :href="href"
                    :target="target" />
            </div>
        </div>
        <Drawer :show="showExperienceDetails" @close="showExperienceDetails = false">
            <template #title>
                <PrismicRichText :field="slice.primary.drawer_tab" class="gst-slice-experience-details__tab--name" />
            </template>
            <template #content>
                <PrismicImage v-if="slice.primary.drawer_image" :field="slice.primary.drawer_image" class="gst-slice-experience-details__tab--image" />
                <div class="gst-slice-experience-details__tab--content my-6 mx-4">
                    <PrismicRichText :field="slice.primary.drawer_title" class="mb-6" />
                    <PrismicRichText :field="slice.primary.drawer_subtitle" class="mb-4" />
                    <div class="d-flex mb-2">
                        <PrismicRichText :field="slice.primary.hotel_name" class="mr-2" />
                        <PrismicImage :field="slice.primary.image_stars" />
                    </div>
                    <PrismicRichText :field="slice.primary.drawer_content" />
                </div>
            </template>
            <template #footer>
                <div class="gst-slice-experience-details__tab--footer d-flex align-center justify-space-between py-4 px-8">
                    <div class="gst-slice-experience-details__price mr-4">
                        <PrismicRichText :field="slice.primary.price" />
                    </div>
                    <BaseButton
                        :disabled="isDisabled"
                        :placeholder="slice.primary.cta_button[0].text"
                        class="gst-slice-primary-button"
                        :href="href"
                        :target="target" />
                </div>
            </template>
        </Drawer>
    </BaseSlice>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import BaseSlice from './components/BaseSlice.vue';
    import Drawer from './components/Drawer.vue';


    export default {
        name: 'ExperienceDetails',
        components: {
            BaseSlice,
            BaseButton,
            Drawer,
        },
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        data( ) {
            return {
                showExperienceDetails: false,
            };
        },
        computed: {
            isPurchaseFlowEnabled() {
                return this.slice.primary.purchase_flow;
            },
            openDrawerTest( ) {
                if ( !this.slice.primary.open_drawer || !this.slice.primary.open_drawer[ 0 ] ) {
                    return;
                }

                return this.slice.primary.open_drawer[ 0 ].text;
            },
            isDisabled() {
                return !this.isPurchaseFlowEnabled || this.slice.primary.cta_button[0].spans[0] === undefined;
            },
            href() {
                return  this.slice.primary.cta_button[0].spans.length > 0 ? this.slice.primary.cta_button[0].spans[0].data.url : '';
            },
            target() {
                return this.slice.primary.cta_button[0].spans.length > 0 ? this.slice.primary.cta_button[0].spans[0].data.target || '_self' : '' ;
            },
        },
        methods: {
            onClickOpenDrawerDo( event ) {
                event.preventDefault( );
                this.showExperienceDetails = !this.showExperienceDetails;
            },
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice-experience-details {
        .gst-slice-experience-details__title h2 {
            margin-bottom: 0 !important;
            border-bottom: none !important;
        }

        .gst-slice-experience-details__content {
            width: 60%;
        }

        .gst-slice-experience-details__tab--content h2 {
            margin-bottom: 24px !important;
        }

        .gst-slice-experience-details__tab--content p {
            margin-bottom: 24px !important;
        }

        .gst-slice-experience-details__tab--content ul {
            margin-bottom: 24px !important;
        }

        .gst-slice-experience-details__price {
            h5 {
                text-align: right;
            }
        }

        .gst-slice-experience-details__tab--footer {
            h5 {
                text-align: left;
            }
        }

        .gst-slice-drawer__header {
            padding: 8px;
        }

        .gst-slice-drawer__content .gst-slice-experience-details__tab--image {
            display: block;
        }

        .gst-slice-experience-details__highlights {
            :where(h1, h2, h3, h4, h5, h6, p) {
                padding-bottom: 8px;
            }

            p:empty {
                margin: 8px 0;
            }
        }

        @include mobile-only {
            .gst-slice-experience-details__content {
                width: 100%;
            }

            .gst-slice-experience-details__price {
                h5 {
                    padding-left: 6px;
                    text-align: left;
                }
            }

            .gst-slice-drawer__content {
                .gst-slice-experience-details__tab--image {
                    width: 100%;
                }
            }
        }
    }

</style>
