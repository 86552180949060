<template>
    <div class="gst-slice-dropdown__row">
        <button
            :aria-expanded="item.isOpen"
            :aria-controls="`collapse${_uid}`"
            class="gst-slice-dropdown__title d-flex flex-row justify-space-between align-center text-left px-2 py-4">
            <slot name="title">
                <PrismicRichText
                    :field="item.data.title" />
            </slot>

            <BaseIcon
                symbol-id="icons--dropdown_arrow"
                class="u-mouse-pointer gst-slice-dropdown__arrow ml-4 flex-shrink-0"
                :class="{
                    'u-rotate-180':item.isOpen,
                    'u-rotate-0': !item.isOpen,
                }" />
        </button>
        <v-fade-transition hide-on-leave>
            <div v-show="item.isOpen" :id="`collapse${_uid}`" class="gst-slice-dropdown__content px-2">
                <slot name="content">
                    <PrismicRichText
                        :field="item.data.content"
                        class="gst-slice__text" />
                </slot>
            </div>
        </v-fade-transition>
        <hr v-show="separator" />
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        components: {
            BaseIcon
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            separator: {
                type: Boolean,
                required: true
            }
        }
    };
</script>
