<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-experience-sub d-flex justify-center" content-class="d-flex flex-column flex-md-row align-center">
        <div class="mr-md-8 mb-6 mb-md-0">
            <PrismicRichText :field="slice.primary.title" class="gst-slice__title" />
            <PrismicRichText
                :field="slice.primary.content"
                class="gst-slice__text" />
        </div>
        <div class="d-flex flex-column flex-md-row align-center ml-md-8">
            <PrismicImage
                v-for="(item, index) in slice.items"
                :key="index"
                :field="item.image" />
        </div>
    </BaseSlice>
</template>

<script>
    import BaseSlice from './components/BaseSlice.vue';
    import NavMixin from './mixins/NavMixin.js';

    export default {
        name: 'ExperienceSub',
        components: {
            BaseSlice
        },
        mixins: [
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice-experience-sub {
        .gst-slice__content {
            > div {
                width: 50%;
            }
        }

        img {
            height: 299px;
            width: 50%;
            padding-right: 16px;
            object-fit: cover;
        }

        img:last-of-type {
            padding-right: 0;
        }

        @include mobile-only {
            .gst-slice__content {
                > div {
                    width: 100%;
                }

                img {
                    height: 299px;
                    width: 100%;
                    padding-right: unset;
                    padding-bottom: 16px;
                    object-fit: cover;
                }

                img:last-of-type {
                    padding-bottom: 0;
                }
            }
        }
    }
</style>
