<template>
    <section class="gst-slice" :class="uniqueIdentifierClass">
        <slot name="content">
            <div
                class="gst-slice__content"
                :class="{
                    [contentClass]: true,
                    'gst-slice__content--width-100': fullWidth,
                    'gst-slice__content--height-100': fullHeight,
                }">
                <slot></slot>
            </div>
        </slot>
    </section>
</template>

<script>
    export default {
        props: {
            fullWidth: {
                type: Boolean,
                default: false
            },
            fullHeight: {
                type: Boolean,
                default: false
            },
            contentClass: {
                type: String,
                default: ''
            },
            sliceType: {
                type: String,
                default: ''
            },
            sliceIndex: {
                type: Number,
                default: 0
            }
        },
        computed: {
            uniqueIdentifierClass() {
                return ( this.sliceType && this.sliceIndex ) ? `gst-slice-${this.sliceType}-${this.sliceIndex}` : '';
            }
        }
    };
</script>
