export default {
    data( ) {
        return {
            contentClamped: false,
            showButtonSeeMore: false
        };
    },
    methods: {
        onClampDo( value ) {
            this.contentClamped = value;
            if ( !this.showButtonSeeMore && value ) {
                this.showButtonSeeMore = true;
            }
        }
    }
};