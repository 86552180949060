<!-- eslint-disable vue/no-v-html -->
<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" class="gst-slice-form-embedded">
        <template v-slot:content>
            <div
                class="gst-slice__content"
                :class="{
                    'gst-slice__content--width-100': fullWidth,
                    'gst-slice__content--height-100': fullHeight,
                }"
                v-html="content">
            </div>
        </template>
    </BaseSlice>
</template>
<!-- eslint-enable vue/no-v-html -->

<script>
    import Vue from 'vue';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'FormEmbedded',
        components: {
            BaseSlice
        },
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        computed: {
            fullWidth( ) {
                return this.slice.primary.full_width;
            },
            fullHeight( ) {
                return this.slice.primary.full_height;
            },
            content( ) {
                if ( this.isTypeForm ) {
                    return this.typeForm[ 0 ].text;
                }
                return '';
            },
            typeForm( ) {
                return this.slice.primary.form_typeform;
            },
            isTypeForm( ) {
                return !!this.typeForm;
            }
        },
        async mounted( ) {
            const loadTypeFormScript = () => {
                return new Promise( ( resolve ) => {
                    Vue.loadScript( '//embed.typeform.com/next/embed.js' )
                        .then( ( ) => resolve( true ) )
                        .catch ( ( ) => resolve( false ) );
                } );
            };
            if ( this.isTypeForm ) {
                await loadTypeFormScript( );
            }
        }
    };
</script>