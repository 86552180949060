<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" full-height class="gst-slice-section-header" content-class="pl-20 pt-10 pb-10 ">
        <PrismicRichText :field="slice.primary.title" class="gst-slice__title" />
    </BaseSlice>
</template>

<script>
    import NavMixin from './mixins/NavMixin.js';
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'SectionHeader',
        components: {
            BaseSlice
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        }
    };
</script>