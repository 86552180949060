<template>
    <div class="gst-slice-modal">
        <div class="gst-slice-modal__content">
            <div class="gst-slice-modal__button-wrapper" tabindex="0" @click="closeModal" @keypress.esc="closeModal">
                <div class="gst-slice-modal__button-close">
                </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        emits: [ 'close-modal' ],
        methods: {
            closeModal() {
                this.$emit( 'close-modal' );
            },
        },
    };
</script>
