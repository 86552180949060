<template>
    <div>
        <div v-if="show" class="gst-slice-drawer-overlay" tabindex="0" @click="$emit( 'close')" @keypress.esc="$emit( 'close')"></div>
        <div
            v-show="show"
            class="gst-slice-drawer d-flex flex-column"
            :class="{
                'gst-slice-drawer--open': show
            }">
            <div v-if="show" class="gst-slice-drawer__header d-flex flex-row align-center">
                <BaseButton
                    icon
                    class="u-rotate-180"
                    @click="$emit('close')">
                    <BaseIcon symbol-id="icons--chevron_right" />
                </BaseButton>
                <div class="flex-grow-1 d-flex flex-row justify-center align-center">
                    <slot name="title"></slot>
                </div>
            </div>
            <div v-if="show" class="gst-slice-drawer__content u-overflow-scroll-y">
                <slot name="content"></slot>
            </div>
            <div v-if="show" class="gst-slice-drawer__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'Drawer',
        components: {
            BaseButton,
            BaseIcon
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
        },
        emits: [
            'close'
        ],
        computed: {
            isMobile( ) {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        watch: {
            show( newValue ) {
                document.querySelector( 'html' ).style.overflow  = newValue ? 'hidden' : 'auto';
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice-drawer-overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba( 0, 0, 0, 0.5 );
        z-index: $z-index-dialog;
    }

    .gst-slice-drawer {
        position: fixed;
        top: 0;
        left: auto;
        right: -600px;
        bottom: 0;
        height: auto;
        width: 576px;
        background: white;
        -webkit-transition: all 0.15s ease-out 0s;
        -moz-transition: all 0.15s ease-out 0s;
        transition: all 0.15s ease-out 0s;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
        transition-delay: 0s;
        transition-property: all;
        z-index: $z-index-dialog + 1;

        @include mobile-only {
            right: -100vw;
            width: 100vw;
        }

        .gst-slice-drawer__content {
            flex: 1;
        }
    }

    .gst-slice-drawer--open {
        right: 0;
    }
</style>