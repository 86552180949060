<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" class="gst-slice-banner-video" :style="$vuetify.breakpoint.smAndUp ? heightStylesDesktop : heightStylesMobile">
        <template v-slot:content>
            <div class="video-container">
                <video :poster="slice.primary.poster"
                    class="video"
                    autoplay
                    muted
                    loop
                    playsinline>
                    <source v-if="slice.primary.video_mp4" :src="slice.primary.video_mp4.url" type="video/mp4" />
                    <source v-if="slice.primary.video_ogv" :src="slice.primary.video_ogv.url" type="video/ogv" />
                    <source v-if="slice.primary.video_webm" :src="slice.primary.video_webm.url" type="video/webm" />
                    Your browser doesn't support HTML5 video.
                </video>
            </div>
        </template>
    </BaseSlice>
</template>

<script>
    import BaseSlice from './components/BaseSlice.vue';

    export default {
        name: 'BannerVideo',
        components: { BaseSlice },
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        computed: {
            heightStylesDesktop() {
                return {
                    height: `${this.slice.primary.height}px`
                };
            },
            heightStylesMobile() {
                return {
                    height: `${this.slice.primary.height_mobile}px`
                };
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-slice-banner-video {
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;

    .video-container {
        position: relative;
        height: 0;
        width: 100%;
        // Aspect ratio 16:9, 9/16 = 0.5625 = 56.25%
        padding-bottom: 56.25%;
    }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
</style>
