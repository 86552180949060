<template>
    <BaseButton
        :disabled="disabled"
        class="gst-slice-navigation-button"
        :class="{
            'u-rotate-180': orientation === 'left'
        }"
        @click="$emit('click')">
        <BaseIcon symbol-id="icons--arrow_right" />
    </BaseButton>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'NavigationButton',
        emits: [ 'click' ],
        components: {
            BaseButton,
            BaseIcon
        },
        props: {
            orientation: {
                type: String,
                default: 'right'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-slice-navigation-button {
        height: 44px !important;
        padding: 0 6px !important;
        margin: 0 !important;
        background: theme-color( 'primary' ) !important;
        min-width: 44px !important;
        border-radius: 0;

        .gst-btn:disabled {
            opacity: 0.4 !important;
        }

        svg {
            .gst-svg-icon {
                fill: theme-color( 'white' );
            }
        }

        &.disabled {
            svg {
                .gst-svg-icon { fill: theme-color( 'septenary' ); }
            }
        }
    }
</style>