<template>
    <BaseButton
        class="gst-slice-see-more-button"
        @click="$emit( 'click') ">
        {{ label }}
        <BaseIcon
            v-if="!seeMore"
            symbol-id="icons--dropdown_arrow"
            class="u-mouse-pointer u-rotate-180-n" />
        <BaseIcon
            v-else
            symbol-id="icons--dropdown_arrow"
            class="u-mouse-pointer" />
    </BaseButton>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';


    export default {
        name: 'SeeMoreButton',
        emits: [ 'click' ],
        components: {
            BaseButton,
            BaseIcon
        },
        props: {
            seeMore: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            label( ) {
                if ( this.seeMore ) {
                    return this.$t( '_common:buttons:seeMore' );
                }
                return this.$t( '_common:buttons:seeLess' );
            }
        }
    };
</script>