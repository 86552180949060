<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" class="gst-slice-spacer" full-width>
        <div :style="style"></div>
    </BaseSlice>
</template>

<script>
    import BaseSlice from './components/BaseSlice.vue';
    import NavMixin from './mixins/NavMixin.js';

    export default {
        name: 'Spacer',
        components: {
            BaseSlice
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        computed: {
            style() {
                return `height: ${this.slice.primary.height.toString()}px; background-color: ${this.slice.primary.background};`;
            }
        }
    };
</script>
