<template>
    <div class="gst-slice-dropdown-list">
        <div v-for="item in localItems"
            :key="item.id">
            <div tabindex="0" @click="toggleDropdownList( item.id )" @keypress.enter="toggleDropdownList( item.id )">
                <Item :item="item" :separator="separator" />
            </div>
        </div>
    </div>
</template>

<script>
    import DropdownListItem from './DropdownListItem.vue';
    import dropdownOptionOpen from '../../utils/constants/dropdownOptionOpen.js';

    export default {
        name: 'DropdownList',
        components: {
            Item: DropdownListItem
        },
        props: {
            items: {
                type: Array,
                required: true
            },
            optionOpen: {
                type: String,
                default: dropdownOptionOpen.SINGLE // Can be Single or Multiple, Multiple open all the elements
            },
            separator: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                localItems: []
            };
        },
        watch: {
            items: {
                deep: true,
                immediate: true,
                handler( ) {
                    this.localItems = this.items.map( ( item, index ) => ( { id: index, isOpen: false, data: item } ) );
                }
            }
        },
        methods: {
            toggleDropdownList( itemId ) {
                if ( this.optionOpen === dropdownOptionOpen.SINGLE ) {
                    this.localItems.forEach( item => item.isOpen = item.id !== itemId ? false : !item.isOpen );
                }

                if ( this.optionOpen === dropdownOptionOpen.MULTIPLE ) {
                    this.localItems.map( item => item.isOpen = !item.isOpen );
                }
            }
        }
    };
</script>
