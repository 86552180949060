<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice-itinerary">
        <div class="gst-slice__content-dark">
            <PrismicRichText :field="slice.primary.title" />
            <PrismicRichText :field="slice.primary.content" />
        </div>
        <SwiperEl
            :items="slice.items"
            :card-width="cardsProps.width"
            :center="false"
            :scroll-left-gutter="$vuetify.breakpoint.smAndDown ? 4 : 8"
            :disable-x-axis-scroll="true"
            class="gst-event-package-offer__swiper">
            <template slot="item" slot-scope="{ item, index }">
                <div
                    class="gst-slice-itinerary__card d-flex flex-column"
                    :class="{
                        'gst-slice-itinerary__card--last': index === slice.items.length - 1
                    }">
                    <PrismicImage :field="item.image" />
                    <div class="gst-slice-itinerary__card-content d-flex flex-column">
                        <TextHeightClamp
                            class="gst-slice-itinerary__card-text"
                            :show-all="false">
                            <PrismicRichText :field="item.title" />
                            <PrismicRichText :field="item.content" class="" />
                        </TextHeightClamp>
                        <a v-if="item.drawer_link_text" href="#" @click="onClickOpenDrawerDo( $event, item, index )">
                            {{ item.drawer_link_text }}
                        </a>
                    </div>
                </div>
            </template>
        </SwiperEl>
        <Drawer
            :show="isItemSelected"
            @close="resetSelectedItem">
            <template v-if="isItemSelected" slot="title">
                <PrismicRichText :field="selectedItem.drawer_header" class="d-flex flex-row align-center justify-center" />
            </template>
            <template v-if="isItemSelected" slot="content">
                <div class="u-height-100 u-overflow-y-scroll">
                    <ImageCarousel :images="selectedItemImages" :height="335" hide-delimiters />
                    <div v-show="slice.primary.date" class="gst-slice__date-section">
                        <h2>{{ dayXHeader }}</h2>
                    </div>
                    <v-fade-transition>
                        <div :key="itemIndex" class="gst-slice-itinerary__drawer-content">
                            <PrismicRichText :field="selectedItem.title" />
                            <PrismicRichText :field="selectedItem.drawer_content" />
                        </div>
                    </v-fade-transition>
                </div>
            </template>
            <template slot="footer">
                <div class="u-height-100 d-flex flex-row align-center">
                    <div class="d-flex align-center">
                        <BaseButton
                            v-show="!isItemFirst"
                            icon
                            @click="selectPreviousItem">
                            <BaseIcon symbol-id="icons--arrow_right" class="u-rotate-180" />
                        </BaseButton>
                        <p v-show="!isItemFirst && slice.primary.date">
                            {{ dayXFooterPrevious }}
                        </p>
                    </div>
                    <div class="flex-grow-1"></div>
                    <div class="d-flex align-center">
                        <p v-show="!isItemLast && slice.primary.date">
                            {{ dayXFooterNext }}
                        </p>
                        <BaseButton
                            v-show="!isItemLast"
                            icon
                            @click="selectNexItem">
                            <BaseIcon symbol-id="icons--arrow_right" />
                        </BaseButton>
                    </div>
                </div>
            </template>
        </Drawer>
    </BaseSlice>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import { format } from '@tenant/app/utils/dateUtils.js';
    import SwiperEl from './components/Swiper';
    import TextHeightClamp from './components/TextHeightClamp.vue';
    import Drawer from './components/Drawer.vue';
    import ImageCarousel from './components/ImageCarousel.vue';
    import BaseSlice from './components/BaseSlice.vue';
    import NavMixin from './mixins/NavMixin.js';

    export default {
        name: 'Itinerary',
        components: {
            BaseSlice,
            SwiperEl,
            TextHeightClamp,
            Drawer,
            BaseButton,
            BaseIcon,
            ImageCarousel
        },
        mixins: [
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        data( ) {
            return {
                selectedItem: { },
            };
        },
        computed: {
            isMobile( ) {
                return this.$vuetify.breakpoint.smAndDown;
            },
            cardsProps( ) {
                if ( this.isMobile ) {
                    return {
                        width: 303
                    };
                }
                return {
                    width: 388
                };
            },
            items( ) {
                return this.slice.items || [ ];
            },
            isItemSelected( ) {
                return !!this.selectedItem.image;
            },
            itemIndex( ) {
                if ( this.isItemSelected ) {
                    return this.items.indexOf( this.selectedItem );
                }
                return null;
            },
            isItemLast( ) {
                return this.itemIndex >= this.items.length - 1;
            },
            isItemFirst( ) {
                return this.itemIndex === 0;
            },
            dayXFooterPrevious( ) {
                const index = this.itemIndex === 0 ? this.itemIndex + 1: this.itemIndex;
                return `Day ${index}`;
            },
            dayXFooterNext( ) {
                return `Day ${this.itemIndex + 2}`;
            },
            dayXHeader( ) {
                return `Day ${this.itemIndex + 1} - ${format( new Date( this.slice.primary.date ), 'EEEE MM dd, yyyy' )}`;
            },
            selectedItemImages(  ) {
                const image = this.selectedItem.image;
                if ( !image ) {
                    return [ ];
                }

                const arrImages =  [
                    image,
                    image.image1,
                    image.image2,
                    image.image3,
                    image.image4,
                    image.image5,
                ]
                    .filter( item => item.url )
                    .map( item => {
                        return {
                            ...item,
                            baseUrl: item.url.split( '?' )[ 0 ]
                        };
                    } );


                return arrImages.filter( ( item, index ) => {
                    return index === arrImages.findIndex( item2 => item2.baseUrl === item.baseUrl );
                } );
            }
        },
        methods: {
            onClickOpenDrawerDo( event, item ) {
                event.preventDefault( );
                this.selectedItem = item;
            },
            resetSelectedItem ( ) {
                this.selectedItem = { };
            },
            selectNexItem( ) {
                this.selectedItem = this.items[ this.itemIndex + 1 ];
            },
            selectPreviousItem( ) {
                this.selectedItem = this.items[ this.itemIndex - 1 ];
            }
        },
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    $slice-background: #000000;
    $slice-text-color: #FFFFFF;
    $slice-card-background: #FFFFFF;
    $slice-action-color: #024DDF;

    .gst-slice.gst-slice-itinerary {
        .gst-slice__content {
            background-color: $slice-background;

            .gst-slice__content-dark {
                color: $slice-text-color;

                h2 {
                    color: inherit;
                    border-bottom: 4px solid transparent;
                }

                h2::after {
                    display: block;
                    height: 4px;
                    width: 31px;
                    margin-top: 5px;
                    background: $slice-card-background;
                    content: '';
                }
            }

            .gst-slice__date-section {
                h2 {
                    color: inherit;
                    border-bottom: 4px solid transparent;
                }

                h2::after {
                    display: block;
                    height: 4px;
                    width: 31px;
                    margin-top: 5px;
                    background: $slice-background;
                    content: '';
                }
            }

            .gst-slice-itinerary__card {
                height: 508px;
                margin-right: 16px;
                background-color: $slice-card-background;

                img {
                    height: 200px;
                    width: 100%;
                    object-fit: cover;
                    object-position: top;
                }

                .gst-slice-itinerary__card-content {
                    height: 250px;
                    padding: 32px 24px 24px 32px;
                    flex: 1;

                    a:last-of-type {
                        margin-top: 4px;
                    }
                }
            }

            .gst-slice-itinerary__card--last {
                margin-right: 0;
            }
        }

        .gst-slice__date-section {
            margin: 32px;
        }

        .gst-slice-drawer {
            .gst-slice-drawer__header {
                padding: 16px;
            }

            .gst-slice-itinerary__drawer-content {
                margin: 24px 32px;

                > div:first-child {
                    padding-bottom: 24px;
                }
            }

            .gst-slice-drawer__footer {
                height: 72px;
                padding: 0 16px;
                box-shadow: 0 2px 20px rgba( 0, 0, 0, 0.12 );

                svg {
                    .gst-svg-icon {
                        fill: $slice-action-color;
                    }
                }
            }
        }
    }
</style>
