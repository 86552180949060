<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" v-bind="navigationAttributes" class="gst-slice gst-slice-gallery-carousel">
        <PrismicRichText :field="slice.primary.title" class="gst-slice__title" />
        <SwiperEl
            v-if="$vuetify.breakpoint.mdAndUp"
            :items="slice.items"
            :center="false"
            :card-width="cardWidth"
            :disable-x-axis-scroll="true"
            class="u-width-100 gst-slice-gallery-carousel__swiper">
            <template slot="item" slot-scope="{ item }">
                <PrismicImage :field="item.image"
                    class="gst-slice-gallery-carousel__swiper-image"
                    @click="openFullScreenGallery(slice.items.indexOf(item))" />
            </template>
        </SwiperEl>
        <v-carousel v-else
            v-model="activeSlide"
            class="gst-slice-gallery-carousel__one-image-swiper"
            :height="'calc(100%-50px)'"
            :show-arrows="false"
            v-bind="$attrs"
            v-on="$listeners">
            <template v-if="slice.items.length !== 1">
                <NavigationButton
                    v-if="showNavigationButtons && activeSlide !== 0"
                    ref="previousButton"
                    class="one-image-swiper__control-prev d-inline"
                    orientation="left"
                    @click="onCarouselClickPrevDo" />
                <NavigationButton
                    v-show="showNavigationButtons && activeSlide !== slice.items.length - 1"
                    ref="nextButton"
                    class="one-image-swiper__control-next"
                    @click="onCarouselClickNextDo" />
            </template>
            <v-carousel-item v-for="(item, index) in slice.items"
                :key="index">
                <BaseImage :src="item.image.url" />
            </v-carousel-item>
        </v-carousel>
    </BaseSlice>
</template>

<script>
    import ImageGalleryModal from '@tenants/ticketmaster/app/components/images/ImageGalleryModal.vue';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import NavigationButton from '@tenants/ticketmaster/app/slices/components/NavigationButton.vue';
    import SwiperEl from './components/Swiper';
    import BaseSlice from './components/BaseSlice.vue';
    import NavMixin from './mixins/NavMixin.js';

    export default {
        name: 'GalleryCarousel',
        components: {
            BaseSlice,
            SwiperEl,
            BaseImage,
            NavigationButton
        },
        mixins: [
            NavMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                activeSlide: 0,
                showNavigationButtons: true
            };
        },
        computed: {
            cardWidth() {
                return 287 + 16 ; //cardWidth + margins
            },
        },
        methods: {
            openFullScreenGallery ( imageIndex ) {
                const items = this.slice.items.map( ( item ) => {
                    return {
                        original: {
                            url: item.image.url
                        },
                        sizes: [ ],
                        type: 'ORIGINAL'
                    };
                } );

                this.$modal.show(
                    ImageGalleryModal,
                    {
                        items: items,
                        initialActiveItem: imageIndex },
                    {
                        'no-click-animation': true,
                        scrollable: false,
                        fullscreen: true,
                        persistent: false,
                        'hide-overlay': false,
                        'content-class': 'gst-v-dialog-gallery',
                    }
                );
            },
            onCarouselClickPrevDo () {
                if( this.activeSlide > 0 ) {
                    this.activeSlide--;
                }
            },
            onCarouselClickNextDo () {
                if( this.activeSlide < this.slice.items.length - 1 ) {
                    this.activeSlide++;
                }
            }
        }
    };
</script>