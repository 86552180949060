<template>
    <BaseSlice :slice-type="slice.slice_type" :slice-index="slice.index" class="gst-slice-info-with-image" v-bind="navigationAttributes" full-width full-height>
        <template>
            <div class=" u-position-relative flex-column-reverse">
                <PrismicImage
                    :field="slice.primary.image"
                    :class="isSeeMoreOpen || !showButtonSeeMore ? 'gst-slice-info-with-image__image--no-clamp' : ''"
                    class="gst-slice-info-with-image__image"
                    :style="generateImageWidthClass" />
                <div class="gst-slice__content d-flex flex-column">
                    <div class="gst-slice__content--text" :class="isSeeMoreOpen || !showButtonSeeMore ? 'gst-slice-text-no-clamp' : 'gst-slice-text-clamp'" :style="$vuetify.breakpoint.mdAndUp ? generateInfoContentWidthClass : ''">
                        <PrismicRichText :field="slice.primary.title" class="gst-slice__title" />
                        <PrismicRichText :field="slice.primary.content" />
                        <div v-if="showButtonSeeMore" :class="isSeeMoreOpen ? '' : 'gst-slice-text-clamp--fade'"></div>
                    </div>
                    <div v-if="showButtonSeeMore">
                        <BaseButton class="gst-slice-see-more-button mt-4" @click="isSeeMoreOpen = !isSeeMoreOpen">
                            {{ label }}
                            <BaseIcon
                                symbol-id="icons--dropdown_arrow"
                                :class="{'u-rotate-180' : isSeeMoreOpen } "
                                class="u-mouse-pointer toggle-down" />
                        </BaseButton>
                    </div>
                </div>
            </div>
        </template>
    </BaseSlice>
</template>

<script>
    import get from 'lodash/get';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseSlice from './components/BaseSlice.vue';
    import NavMixin from './mixins/NavMixin.js';

    export default {
        name: 'InfoWithImage',
        components: {
            BaseButton,
            BaseIcon,
            BaseSlice
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                isSeeMoreOpen: false,
            };
        },
        computed: {
            imageWidth ( ) {
                return this.$vuetify.breakpoint.xlOnly ? this.slice.primary.image_width - 3 : this.slice.primary.image_width;
            },
            infoContentWidth ( ) {
                return ( 100 - parseFloat( this.imageWidth ) - 5 );
            },
            generateImageWidthClass () {
                return `width: ${ this.imageWidth - 1}%`;
            },
            generateInfoContentWidthClass () {
                return `width: ${ this.infoContentWidth }%`;
            },
            renderSectionId () {
                const navigationText = get( this.slice, 'primary.nav[0].text' );
                return  navigationText ? navigationText : '';
            },
            showButtonSeeMore() {
                return this.slice.primary.show_see_more;
            },
            label() {
                if ( this.isSeeMoreOpen ) {
                    return this.$t( '_common:buttons:seeLess' );
                }
                return this.$t( '_common:buttons:seeMore' );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-slice-info-with-image {
    .gst-slice-text-clamp--fade {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-bottom: 120px;
        background-image: linear-gradient( rgba( 255, 255, 255, 0 ), rgb( 255, 255, 255 ) );
    }

    .toggle-down {
        transition: transform 0.3s ease-out 0s;
    }

    .gst-slice-info-with-image__image--no-clamp {
        max-height: 515px;
    }

    .gst-slice-text-no-clamp {
        max-height: auto;
        overflow: hidden;
    }

    .gst-slice-text-clamp {
        max-height: 325px;
        overflow: hidden;
    }
}

@include desktop-only {
    .gst-slice-info-with-image {
        .gst-slice__title {
            margin-bottom: 24px;
        }

        .gst-slice__footer {
            margin-top: 16px;
            margin-bottom: 48px;
        }

        .gst-slice__content {
            > div {
                margin-right: 25px;
            }
        }

        .gst-slice-info-with-image__image {
            position: absolute;
            right: 0;
            height: 100%;
            z-index: 1;
            object-fit: cover;
        }
    }
}

@include mobile-only {
    .gst-slice-info-with-image {
        .gst-slice__title {
            margin-bottom: 24px;
        }

        .gst-slice__footer {
            margin-top: 16px;
            margin-bottom: 48px;
        }

        .gst-slice-text-no-clamp {
            max-height: none;
        }

        .gst-slice-text-clamp {
            max-height: 250px;
        }

        .gst-slice-info-with-image__image {
            width: 100% !important;
            object-fit: cover;
        }
    }

    .gst-slice__content--text {
        width: 100% !important;
    }
}
</style>